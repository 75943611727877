<script>
import { VForm } from 'vuetify/lib'

export default {
  extends: VForm,
  provide () {
    return {
      forms: this.forms
    }
  },
  inject: {
    forms: { default () { return new Set() } }
  },
  beforeDestroy () {
    this.forms.delete(this)
  },
  mounted () {
    this.forms.add(this)
  },
  methods: {
    resetAll () {
      this.forms.forEach(form => form.reset())
    },
    resetAllValidation () {
      this.forms.forEach(form => form.resetValidation())
    },
    validateAll () {
      this.forms.forEach(form => form.validate())
    }
  },
  watch: {
    inputs () {
      this.validateAll()
    }
  }
}
</script>
